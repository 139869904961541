<template>
  <div class="product__detail__desc">
    <textarea
      name="Text1"
      cols="50"
      rows="2"
      placeholder="Titulo del producto"
      class="product__detail__desc__title"
      v-model="description.title"
      :disabled="accepted"
    >
    </textarea>
    <div class="product__detail__desc__brand">
      <span class="brand">Marca: </span>
      <select :disabled="accepted" name="bad_day" class="brand__options" v-model="description.brand">
        <option 
            :value="brand.name" 
            v-for="brand in getAllBrands" 
            :key="brand.id"
        >{{brand.name}}</option>
      </select>
    </div>
    <div class="product__detail__desc__details">
      <span class="stock" @click="description.stock = !description.stock">
        <b-icon 
          :icon="description.stock ? 'check2' : 'x'" 
          :variant="description.stock ? 'success' : 'danger'">
          </b-icon> 
          {{description.stock ? 'En existencia' : 'Agotado'}}
      </span>
      <span class="code">Código del producto: </span>
      <input 
        type="text" 
        class="code__input" 
        name="price-code"
        placeholder="Código del producto"
        v-model="description.code"
        :disabled="accepted"
      >
    </div>
    <div class="product__detail__desc__text">
      <h5>Descripcion:</h5>
      <textarea 
        name="product-desc" 
        cols="50"
        rows="2"
        placeholder="Descripcion del producto"
        v-model="description.description"
        class="product__description"
        :disabled="accepted"
      ></textarea>
    </div>
    <div class="product__detail__desc__esp">
      <h5>Acerca de este artículo: </h5>
      <div class="add__specification">
        <textarea 
        name="about-product" 
        cols="50" 
        rows="10"
        placeholder="Ingrese la especificacion"
        v-model="newSpecification"
        @keyup.enter="addSpecification"  
        :disabled="accepted"
      >
      </textarea>
      <button @click="addSpecification" :disabled="accepted">Añadir especificacion</button>
      </div>
      <ul v-if="description.specifications.length > 0">
        <li 
          v-for="(e, idx) in description.specifications" 
          :key="idx"
        >
        {{e}}
        </li>
      </ul>
    </div>

    <div class="product__desc__accept" v-show="showAcceptButton">
      <button @click="emitDescription">Aceptar</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    productInfo: {
      type: Object,
    }
  },

  data() {
    return {
      description: {
        title: '',
        brand: '',
        stock: true,
        code: '',
        description: '',
        specifications: []
      },
      newSpecification: '',
      accepted: false
    };
  },

  created() {
    if(this.productInfo) {
      if(!this.productInfo.specifications) {
        this.productInfo.specifications = []
      }
      this.description = this.productInfo
    }
  },

  methods: {
    addSpecification() {
      if(this.newSpecification.trim().length > 0){
        this.description.specifications.push(this.newSpecification);
        this.newSpecification = ''
      }
    },

    emitDescription() {
      this.accepted = true
      this.$emit('desc', this.description)
    }
  },

  computed: {
    showAcceptButton() {
      if(this.description.title.trim().length > 0 && this.description.brand && this.description.code.trim().length > 0 && this.description.description.trim().length > 0){
        return true
      }
      return false
    },
    ...mapGetters('webStore', ['getAllBrands'])   
  }
};
</script>
